.navbar55 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
  background-color: #fff;
  color: #000;
  position: fixed;
  z-index: 22;
  height: 100px;
}
@media only screen and (max-width: 600px) {
  .dis {
    display: flex;
    justify-content: space-between;
  }
  .mobilem {
    margin-top: 34px;
    margin-left: 20px;
  }
}

.navbar-logo55 img {
  /* font-size: 24px; */
  width: 92px;
  margin-left: 80px;
}

.navigation-items55 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.navigation-items55 li {
  margin-right: 20px;
  font-size: 18px;
}
.navigation-items55 li a {
  text-decoration: none;
  color: #099080;
}
/* .navigation-items55 li a:hover {
    color: #9f814b;
  } */

.menu-toggle55 {
  background: none;
  border: none;
  cursor: pointer;
}

.menu-icon55 {
  width: 25px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.menu-buttons-55 {
  display: flex;
  flex-direction: row;
}

.menu-button55 a {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  font-family: "Montserrat";
  border: 1px solid #00CAB2;
  padding: 12px 30px;
  border-radius: 50px;
  transition: 0.5s;
  margin-left: 5px;
  text-decoration: none;
  color: white;
  background-color: #00CAB2;
}
.menu-button55 a:hover {
  background: #099080;
  text-decoration: none;
  color: white;
}

@media (max-width: 1116px) {
  .bar55 {
    width: 100%;
    height: 3px;
    background-color: #000;
  }
  .navbar55 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .navbar-menu55 {
    width: 100%;
    display: none;
    background-color: #000000;
  }

  .navigation-items55 {
    flex-direction: column;
  }

  .navigation-items55 li {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .navigation-items55 li a {
    padding-left: 25px;
  }
  ul.navigation-items55 {
    height: 380px;
  }

  .navbar-logo55 img {
    margin-left: 20px;
  }

  .menu-toggle55 {
    position: absolute;
    top: 37px;
    right: 15px;
  }

  .navbar-menu55.open {
    display: block;
  }
  .navbar-logo55 img {
    /* font-size: 24px; */
    width: 80px;
    margin-top: 22px;
  }
  .menu-buttons-55 {
    display: flex;
    margin: 20px 15px;
  }
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background: white;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    text-align: center;
    z-index: 99999;
  }
  @media only screen and (max-width: 600px) {
    .popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 300px;
        background: white;
        padding: 20px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        text-align: center;
        z-index: 99999;
      }
  }
  .popup-logo {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  .close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
  }

  .timers {
    display: inline-block;
    font-size: 40px;
    margin-top: 0px;
    border: 1px solid #101010;
    background: #009d8a;
    color: #fff;
    margin: 10px;
    padding: 13px 13px;
}
button{
    background: #fbb6b6;
    border: 2px solid #000;
    color: #000;
    padding: 5px 22px;
    font-weight: bold;
}
  